<template>
  <section class="section__container">
    <div class="section__container_background">
      <img src="../../assets/conteudos_ser_atingido_background_01.png" alt="" />

      <GoBack />
    </div>
    <div class="container position--relative">
      <section class="container">
        <v-row class="d-flex justify-center flex-column align-center">
          <div class="d-flex align-self-start pt-2 pb-2">
            <Breadcrumb
              style="color: white"
              class="breadcumb--item-color__scoped conteudos_tematicos_section_bc-color"
            >
              <template v-slot:breadcrumbLinks>
                <router-link
                  tag="p"
                  :to="{ name: link.href }"
                  v-for="(link, idx) in breadcrumbItems"
                  :key="idx"
                >
                  {{ link.text }}
                </router-link>
              </template>
            </Breadcrumb>
          </div>
          <v-col class="d-flex justify-center align-center">
            <img
              width="50px"
              src="../../assets/conteudos_ser_atingido_title_diamond.png"
              alt=""
            />
            <h3 class="ml-3">
              Afinal, o que é ser atingido ?
            </h3>
          </v-col>
        </v-row>
      </section>
      <section class="container">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="8">
            <Card borderColor="#426E51" backgroundColor="#F9F7ED">
              <template v-slot:conteudo>
                <p>
                  A definição do que é ser atingido parece ser algo simples. São
                  atingidos, por exemplo, pelo rompimento de uma barragem de
                  rejeitos ou pela construção de uma hidrelétrica aqueles que
                  tiveram suas vidas de alguma forma modificadas por tais
                  eventos.
                </p>
                <p>
                  No entanto, na prática, diante de um acontecimento de grandes
                  proporções como o rompimento da barragem de Fundão, ocorrido
                  em Mariana-MG, a definição do que é ser atingido não é uma
                  tarefa nada simples. Muito pelo contrário, o alcance desse
                  termo é objeto de muita disputa. Uma definição restrita do que
                  é ser atingido deixa sem reparação muitas pessoas que sofreram
                  danos. E, infelizmente, é este o caminho que a Fundação
                  Renova<sup>1</sup> percorreu e continua a percorrer na
                  reparação dos danos ocasionados pelo rompimento.
                </p>
                <p>
                  O “Dicionário Crítico da Mineração” define o que é ser
                  atingido como “Indivíduos e grupos que sofrem com os [impactos
                  da mineração], mesmo os desconsiderados oficialmente pela
                  [avaliação de impacto ambiental], mineradoras e pelo poder
                  público” (WANDERLEI, 2018, p. 31).
                </p>
                <p>
                  Carlos Vainer, pesquisador do Instituto de Pesquisa e
                  Planejamento Urbano e Regional da UFRJ, entende que atingido é
                  um conceito em constante disputa, que se altera por razões de
                  tempo e espaço, bem como de contextos políticos e culturais. E
                  que, de fato, reconhecer alguém como atingido significa
                  conferir legitimação para o ressarcimento pelos danos
                  sofridos.
                </p>
                <p>
                  Já as professoras Tatiane Ribeiro e Karine Gonçalves (2019, p.
                  5) definem a palavra atingido da seguinte forma:
                </p>
                <p style="background-color: #F3F3F3; padding: 1em;">
                  A palavra “atingido” tem o seu significado em disputa, porque
                  ela tende a promover uma identificação entre as pessoas que
                  sofrem os efeitos das atividades minerárias, reforçando o
                  sentimento de pertencimento a um campo de luta por direitos.
                </p>
                <p>
                  A partir das definições acima, entende-se que são atingidos
                  pelo rompimento da barragem de Fundão aqueles que perderam um
                  familiar ou amigo, suas casas e demais bens materiais, suas
                  fontes de sustento, o sentimento de pertencimento à comunidade
                  em que cresceram, qualidade de vida, memórias, espaço,
                  costumes, a integridade física e emocional, dentre tantas
                  outras perdas. Ou seja, todos aqueles a quem foi imposta uma
                  nova forma de vida, que tiveram rotinas e planos arrancados e
                  foram obrigados a lidar com uma nova realidade nunca desejada.
                </p>
                <p>
                  Para os que sofreram o impacto do rompimento da barragem de
                  Fundão, ser atingido vai além de definições técnicas,
                  econômicas ou sociais. Ser atingido não se resume ao
                  sofrimento suportado pela população e a seu reconhecimento
                  como afetados pelos rejeitos de lama. Abarca também o
                  reconhecimento de direitos e a legitimação de todas as pessoas
                  afetadas como dignas de reparação.
                </p>
                <p>
                  Ser atingido pelo rompimento da barragem de Fundão vai desde
                  passar pelo sofrimento de ter toda sua vida transformada
                  contra sua vontade, pela necessidade de enfrentar lutas para
                  ter o seu sofrimento reconhecido e, por fim, conseguir que o
                  seu direito de reparação seja legitimado.
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container">
        <v-row class="d-flex justify-center align-center mt-5">
          <v-col cols="12" md="8">
            <Card
              borderColor="#426E51"
              backgroundColor="#F9F7ED"
              title="As dificuldades em se reconhecer como uma pessoa atingida"
              titleColor="#426E51"
              title_alignment="center"
            >
              <template v-slot:conteudo>
                <p>
                  Tão logo aconteceu o rompimento da barragem de Fundão, se
                  reconhecer como pessoa atingida foi motivo de angústia por
                  aqueles que sofreram a grande diversidade de danos que este
                  evento causou. Infelizmente, o rompimento ocasionou a morte de
                  19 pessoas e dezenas de famílias foram expulsas de suas casas.
                  Elas tiveram que deixar tudo para trás para salvarem suas
                  vidas, pois uma avalanche de rejeitos de lama se aproximava
                  delas de forma violenta.
                </p>
                <p>
                  Diante das mortes e da situação de vulnerabilidade dos que
                  ficaram desabrigados, muitos dos que tiveram outras perdas,
                  para além dessas, não se sentiram confortáveis em se
                  reconhecerem como pessoas atingidas pelo rompimento. Soma-se a
                  isso o fato de que, nos momentos que se sucederam ao
                  rompimento, não era possível dimensionar todos os danos a que
                  foram submetidos e que ainda viriam a suportar.
                </p>
                <p>
                  Pessoas desabrigadas ainda acreditavam que poderiam voltar
                  para suas casas, e as que não tiveram suas propriedades
                  alcançadas pela lama imaginavam que a vida voltaria ao normal
                  em pouco tempo. Logo, diante de tanto caos e desamparo, até
                  mesmo a própria percepção do indivíduo como atingido lhes foi
                  tomada. Em face da imensa perda de alguns, vários outros se
                  sentiram envergonhados em declarar os danos sofridos, mesmo
                  tendo perdido tantas outras coisas.
                </p>
                <p>
                  No dia 05 de novembro de 2015, muitas pessoas perderam o local
                  onde criaram seus filhos, as praças, igrejas e cachoeiras que
                  frequentavam, o convívio com vizinhos, amigos e familiares que
                  residiam a poucos metros uns dos outros, a terra em que
                  produziam seu alimento, a qualidade de sua alimentação,
                  objetos que materializavam memórias, dentre várias outras
                  perdas.
                </p>
                <p>
                  As empresas responsáveis pelo rompimento classificaram as
                  pessoas atingidas em duas categorias: as que foram diretamente
                  atingidas e as indiretamente atingidas pelos danos causados
                  por elas. Essa prática desmerece os danos sofridos pelas
                  pessoas classificadas como indiretamente atingidas, pois as
                  coloca como menos atingidas e, portanto, menos dignas de
                  reparação.
                </p>
                <p>
                  A ideia de que havia danos menos dignos de reparação fez com
                  que diversas perdas deixassem de ser relatadas, tanto em razão
                  de julgamentos internos quanto de terceiros.
                </p>
                <p>
                  Com o passar dos anos, se torna cada vez mais evidente quão
                  vastos são os reflexos danosos do rompimento da barragem de
                  Fundão na vida de várias pessoas de diversas formas. A divisão
                  entre atingidos diretos e indiretos foi e ainda é utilizada
                  para constranger as pessoas a não declararem os danos que
                  sofreram e, por consequência, gerar economia às empresas
                  responsáveis. Essa categorização deixa sem reparação milhares
                  de pessoas que tiveram suas vidas forçadamente modificadas
                  pelo rompimento.
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="d-flex justify-center align-center mt-5">
          <v-col cols="12" md="8">
            <Card
              borderColor="#426E51"
              backgroundColor="#F9F7ED"
              title="O histórico de dependência da mineração da cidade de Mariana-MG e a dificuldade de ser uma pessoa atingida por uma grande mineradora nesta cidade"
              titleColor="#426E51"
              title_alignment="center"
            >
              <template v-slot:conteudo>
                <p>
                  A economia do município de Mariana, estado de Minas Gerais,
                  está ligada à mineração desde os seus primórdios. Em 1696, a
                  chegada dos bandeirantes ao território deu início à exploração
                  do ouro encontrado no rio Ribeirão do Carmo. Desde então, a
                  mineração é a atividade econômica principal da cidade.
                </p>
                <p>
                  O desenvolvimento industrial do Brasil mudou o foco da
                  mineração local para a extração de minério de ferro, pois este
                  é matéria-prima essencial para produção de aço. E sem o aço
                  não há industrialização de setores como o automobilístico, de
                  eletrodomésticos, da construção civil, dentre outros.
                </p>
                <p>
                  O minério de ferro presente no município de Mariana-MG trouxe
                  à cidade grandes mineradoras como a Samarco Mineração S/A que
                  chegou à região em 1977. Também se estabeleceram na cidade a
                  Companhia Vale do Rio Doce<sup>2</sup> e a extinta S/A
                  Mineração Trindade (Samitri).
                </p>
                <p>
                  Desde então, tais empresas dominam o cenário econômico da
                  cidade. O município de Mariana-MG não diversificou sua
                  economia e depende das grandes mineradoras para a arrecadação
                  de recursos públicos e geração de empregos. Duarte Júnior,
                  prefeito da cidade de Mariana à época do rompimento, disse à
                  BBC News em reportagem de 2007 que "Nós passamos do ciclo do
                  ouro para o ciclo do minério. Nunca nos preparamos para viver
                  sem a mineração".
                </p>
                <p>
                  A exploração em larga escala dos recursos minerais gera muitos
                  empregos diretos e indiretos e torna essa atividade
                  extremamente lucrativa. E quanto maiores os lucros, maior é a
                  arrecadação de tributos e royalties aos cofres públicos
                  municipais.
                </p>
                <p>
                  Logo, percebe-se o poder econômico que as mineradoras exercem
                  na cidade, pois provém de suas atividades exploratórias grande
                  parte do sustento do município de Mariana-MG, bem como de sua
                  população.
                </p>
                <p>
                  No entanto, a exploração mineral que gera empregos e renda
                  coloca em constante risco tanto pessoas, quanto o meio
                  ambiente. O rompimento da barragem de Fundão foi resultado da
                  mineração irresponsável e depredatória praticada pela Samarco
                  Mineração S/A. Este evento causou irreparáveis violações aos
                  direitos humanos das pessoas atingidas como mortes,
                  desaparecimento, traumas psicológicos e alteração do seu modo
                  de vida de diversas formas, desde a retirada forçada do local
                  onde viviam, bem como perda de renda, do contato com a
                  natureza e com amigos e familiares que não mais residem
                  próximos uns aos outros.
                </p>
                <p>
                  A já citada dominação econômica das mineradoras frente aos
                  moradores e ao município de Mariana é potencializada pela
                  relação desigual entre as empresas responsáveis pela barragem
                  de Fundão e as pessoas atingidas pelo seu rompimento.
                </p>
                <p>
                  Como exemplo dessa dominação econômica, cita-se a Fase de
                  Negociação Extrajudicial (FNE)<sup>3</sup> que, a princípio,
                  foi vista como uma conquista para as pessoas atingidas. A FNE
                  foi criada para que as pessoas atingidas pudessem ser
                  indenizadas de forma mais rápida e menos burocrática e com
                  garantias e direitos estabelecidos. No entanto, na prática, a
                  FNE desvirtuou suas finalidades, bem como princípios e
                  institutos jurídicos em detrimento das pessoas atingidas e com
                  o fim de gerar economia às empresas rés. Convida-se à leitura
                  do Parecer Técnico Jurídico produzido pela Assessoria Técnica
                  Cáritas onde foram relatadas as violações que sua equipe de
                  Assessoria Jurídica pode observar durante a prática
                  profissional nesse espaço. Tais violações são praticadas em
                  descumprimento de acordos homologados judicialmente, e nada é
                  feito no sentido de evitar injustiças, fato que pode ser
                  atribuído ao poder econômico das mineradoras na região e no
                  Brasil.
                </p>
                <p>
                  Logo, percebe-se que o poder econômico assumido pelas grandes
                  mineradoras gera como consequência violações de direitos
                  humanos e que a mineração deve ser exercida com
                  responsabilidade e não pautada na ganância por lucros cada vez
                  mais altos, sem levar em conta a cadeia socioeconômica e os
                  aspectos culturais das comunidades locais.
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="d-flex justify-center align-center mt-5">
          <v-col cols="12" md="8">
            <Card
              borderColor="#426E51"
              backgroundColor="#F9F7ED"
              title="O preconceito como outro fator que traz dificuldades no reconhecimento como pessoa atingida "
              titleColor="#426E51"
              title_alignment="center"
            >
              <template v-slot:conteudo>
                <p>
                  As pessoas atingidas pelo rompimento da barragem de Fundão da
                  cidade de Mariana-MG residiam ou ainda residem em distritos e
                  subdistritos da cidade. Diante da comoção pública causada pelo
                  rompimento, elas receberam muitas demonstrações de
                  solidariedade vindas de diversas pessoas, em especial dos
                  moradores da sede do município de Mariana-MG.
                </p>
                <p>
                  No entanto, pouco tempo após o rompimento, a relação das
                  pessoas atingidas com parte dos moradores da sede do município
                  de Mariana-MG se deteriorou.
                </p>
                <p>
                  A suspensão das atividades da Samarco Mineração S/A trouxe
                  desemprego, queda de faturamento do comércio e substancial
                  perda de arrecadação aos cofres municipais. Essa nova
                  realidade econômica repercutiu de forma negativa nas vidas das
                  pessoas atingidas. Parte dos moradores da sede da cidade que
                  tiveram suas condições econômicas abaladas passou a adotar
                  práticas preconceituosas contra as pessoas atingidas. Assim,
                  além das dificuldades de adaptação em local totalmente diverso
                  do que estavam habituados e de tantas outras perdas que
                  geraram grande abalo emocional, as pessoas atingidas passaram
                  a ser vistas como responsáveis pelo abalo econômico que a
                  cidade e seus moradores passavam.
                </p>
                <p>
                  Ao longo das ruas da cidade era possível ver placas e cartazes
                  fixados em casas e em estabelecimentos comerciais com dizeres
                  como “Somos todos atingidos”, “Justiça sim, desemprego não” e
                  “Fica Samarco”.
                </p>
                <p>
                  São inúmeros os relatos de pessoas atingidas que passam por
                  situações vexatórias ao terem que escutar sobre os
                  “privilégios” de ser uma pessoa atingida e sobre sua suposta
                  responsabilidade pelo declínio econômico sofrido pela cidade e
                  seus moradores. Isso faz com que, por muitas vezes, as pessoas
                  atingidas acabem por negar sua condição perante a sociedade
                  para se protegerem. Inclusive para conseguir se inserir no
                  mercado de trabalho na cidade, o fato de ser atingido é por
                  vezes omitido.
                </p>
                <p>
                  Entende-se que essa realidade muito se deve à desinformação da
                  população marianense sobre as práticas, perpetuamente danosas,
                  das empresas responsáveis pelo rompimento frente às pessoas
                  atingidas. E também da publicidade tendenciosa da Fundação
                  Renova de que o processo de reparação de danos está avançado e
                  promove justiça.
                </p>
                <p>
                  O sentimento de vergonha de ser atingido é um desvirtuamento,
                  por completo, do que é ser atingido e é uma nefasta
                  consequência do poder das mineradoras na cidade de Mariana.
                  Diante disso, percebe-se que as pessoas atingidas pelo
                  rompimento da barragem de Fundão não tiveram seus danos
                  consolidados ou finalizados no dia 05 de novembro de 2015.
                  Lutar por uma definição justa do que é ser atingido e se
                  reconhecer como tal são lutas que ainda estão sendo travadas
                  por esses indivíduos. Sobretudo em um ambiente de dominação
                  econômica da mineração e preconceito.
                </p>
                <p style="text-align: center">
                  <strong
                    >Autoras: Larissa Bezerra Cota<sup>4</sup> e Raquel
                    Wanderley Xavier<sup>5</sup></strong
                  >
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container pb-10 mt-4">
        <v-row class="d-flex">
          <v-col cols="12">
            <div class="inside__card">
              <p style="text-align: center;"><strong>Foto:</strong></p>
              <p style="text-align: center;">
                Professora Angélica, atingida de Paracatu de Baixo, com a placa
                esculpida em madeira salva por ela no dia do rompimento. Fonte:
                Ane Souz/Jornal A Sirene, 2016.
              </p>
            </div>
          </v-col>
        </v-row>
      </section>

      <section class="container pb-10 mt-4">
        <v-row class="d-flex">
          <v-col cols="12">
            <div class="inside__card">
              <p>
                <sup>1</sup> Fundação Renova é uma Fundação de Direito Privado
                criada para gerir e executar as ações de reparação dos danos
                decorrentes do rompimento da barragem de Fundão.
              </p>
              <p>
                <sup>2</sup> A Companhia Vale do Rio Doce era uma empresa
                estatal que foi privatizada em 1996 e deu origem à empresa
                privada Vale S/A.
              </p>
              <p>
                <sup>3</sup> Fase de Negociação Extrajudicial (FNE) foi criada
                por um Termo de Acordo e Ajustamento de Conduta entre Ministério
                Público e empresas responsáveis pelo rompimento da barragem de
                Fundão para que as pessoas atingidas pudessem negociar suas
                indenizações com a Fundação Renova de forma extrajudicial.
                Aplicada apenas no território de Mariana-MG.
              </p>
              <p>
                <sup>4</sup> Advogada pela UFOP, especialista em Direito
                Administrativo pela POSEAD-FGF, em Gestão Pública Municipal pela
                UFJF e em Direito Penal e Processual Penal pela Estácio.
                Assessora jurídica na ATI Mariana.
              </p>
              <p>
                <sup>5</sup> Advogada pela UFOP, especialista em Direito Público
                pela Faculdade UniAmérica. Assessora jurídica na ATI Mariana.
              </p>
            </div>
          </v-col>
        </v-row>
      </section>

      <section class="container pb-10 mt-4 mb-section">
        <v-row class="d-flex">
          <v-col cols="12">
            <div class="inside__card">
              <p>
                <strong>Referências Bibliográficas</strong>
              </p>
              <p>
                CARNEIRO, Karine Gonçalves; SOUZA, Tatiana Ribeiro. O direito
                das “pessoas atingidas” à assessoria técnica independente: o
                caso de Barra Longa (MG). Disponível em
                <a
                  target="_blank"
                  style="text-decoration: none"
                  href="https://www.revista.ueg.br/index.php/sapiencia/article/view/9817"
                  class="pink--text"
                  >https://www.revista.ueg.br/index.php/sapiencia/article/view/9817</a
                >. Acesso em: 04 mar. 2022.
              </p>
              <p>
                MOTA, Camila Veras. Sobreviventes de desastre de Mariana sofrem
                preconceito, e moradores pedem volta da Samarco. BBC online,
                2017.
              </p>
              <p>
                VAINER, Carlos B. Conceito de “atingido” uma revisão de debates
                e diretrizes. Rio de Janeiro: IPPUR/UFRJ, jul./2005, p. 1-23.
                Disponível em
                <a
                  target="_blank"
                  style="text-decoration: none"
                  href="https://docplayer.com.br/52010953-Conceito-de-atingido-uma-revisao-do-debate-e-diretrizes-1.html"
                  class="pink--text"
                  >https://docplayer.com.br/52010953-Conceito-de-atingido-uma-revisao-do-debate-e-diretrizes-1.html</a
                >. Acesso em: 07 mar. 2022.
              </p>
              <p>
                WANDERLEY, Luiz Jardim de Morais (org). Dicionário Crítico da
                Mineração. Marabá: Iguana. 2018. Disponível em:
                <a
                  target="_blank"
                  style="text-decoration: none"
                  href="https://sites.ufop.br/sites/default/files/gepsa/files/2018-dicionario-critico-da-mineracao.pdf?m=1594825417"
                  class="pink--text"
                  >https://sites.ufop.br/sites/default/files/gepsa/files/2018-dicionario-critico-da-mineracao.pdf?m=1594825417</a
                >. Acesso em: 04 mar. 2022.
              </p>
            </div>
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="d-flex justify-center align-center pa-4 section__mb">
          <v-col cols="12" md="7" class="d-flex justify-center align-center">
            <v-btn
              :to="{ name: 'conteudos_tematicos_ser_atingido_discriminacao' }"
              color="#EDBD43"
            >
              <strong>Conhecer "Discriminação"</strong>
            </v-btn>
          </v-col>
        </v-row>
      </section>
    </div>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    GoBack,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Ser Atingido /",
          href: "conteudos_tematicos_ser_atingido",
        },

        {
          text: "Afinal, o que é ser atingido ?",
          href: "conteudos_tematicos_ser_atingido",
        },
      ],
    };
  },
  created() {},
};
</script>

<style scoped>
.section__container {
  position: relative;
}

.section__container_background {
  position: fixed;
  top: 0;
}

h3 {
  color: white;
}

@media (max-width: 600px) {
  .section__container_background {
    position: relative;
    display: flex;
    justify-content: center;
  }

  h3 {
    color: black;
  }
}

@media (min-width: 1400px) {
  .section__container_background {
    display: flex;
    justify-content: center;
  }
}

.position--relative {
  position: relative;
}

.card__footer_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card__footer_img img {
  width: 40%;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.section__mb {
  padding-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
